// import Header from './Header';
// import Video from './Video';
// import WhyChoose from './WhyChoose';
// import CountryFlags from './CountryFlags';
// import LoanProcess from './LoanProcess';
// import Counter from './Counter';
// import PartnerLogos from './PartnerLogos';
// import Footer from './Footer';

// import './App.css';


// function App() {
//   return (
//     <div className="App">
//       <Header />


//       <Video />


//       <WhyChoose />

//       <CountryFlags />

//       <LoanProcess />

//       <Counter />

//       <PartnerLogos />

//       <Footer /> 

//     </div>
//   );
// }

// export default App;


// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import Video from './components/home/Video';
import WhyChoose from './components/home/WhyChoose';
import CountryFlags from './components/home/CountryFlags';
import LoanProcess from './components/home/LoanProcess';
import Counter from './components/home/Counter';
import PartnerLogos from './components/home/PartnerLogos';
import Footer from './Footer';
import Services from './components/services/Services'; // Import the Services component
import StudyAbroad from './components/studyAbroad/StudyAbroad'; // Import the StudyAbroad component
import AboutUs from './components/aboutUs/AboutUs'; // Import the AboutUs component
import ContactUs  from './components/contactUs/ContactUs'; // Import the ContactUs component
import WhatsAppButton from './components/WhatsAppButton/WhatsAppButton';


import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <Video />
              <WhyChoose />
              <CountryFlags />
              <LoanProcess />
              <Counter />
              <PartnerLogos />
              <WhatsAppButton />

            </>
          } />
          <Route path="/services" element={<Services />} />
          <Route path="/study-abroad" element={<StudyAbroad />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
