import React, { useState } from 'react';
import './EMICalculator.css'; // Import CSS for styling

const EMICalculator = ({ onClose }) => {
  const [loanAmount, setLoanAmount] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [loanTerm, setLoanTerm] = useState('');
  const [emi, setEMI] = useState(null);

  const calculateEMI = () => {
    // Your EMI calculation logic goes here
    // For example:
    const principal = parseFloat(loanAmount);
    const rate = parseFloat(interestRate) / 1200; // Monthly interest rate
    const time = parseFloat(loanTerm);

    const emiValue = (principal * rate * Math.pow(1 + rate, time)) / (Math.pow(1 + rate, time) - 1);
    setEMI(emiValue.toFixed(2));
  };

  return (
    <div className="emi-calculator">
      <div className="emi-content">
        <h2>EMI Calculator</h2>
        <div className="emi-inputs">
          <label>Loan Amount:</label>
          <input type="number" value={loanAmount} onChange={(e) => setLoanAmount(e.target.value)} />
          <label>Interest Rate (%):</label>
          <input type="number" value={interestRate} onChange={(e) => setInterestRate(e.target.value)} />
          <label>Loan Term (Months):</label>
          <input type="number" value={loanTerm} onChange={(e) => setLoanTerm(e.target.value)} />
        </div>
        <button onClick={calculateEMI}>Calculate EMI</button>
        {emi && <div className="emi-result">Your EMI: {emi}</div>}
        <button onClick={onClose}>Close</button> {/* Use the onClose prop to close the component */}
      </div>
    </div>
  );
};

export default EMICalculator;
