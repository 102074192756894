// src/components/studyAbroad/Carousel.js
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Carousel.css';
import img1 from './image/img1.avif';
import img2 from './image/img2.avif';
import img3 from './image/img3.avif';
import img4 from './image/img1.jpeg';


const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [
    { 
      src: img1, 
      title: 'First Slide',
      description: 'This is the first slide description.',
      button1: 'Check Eligibility',
      button2: 'Contact Now'
    },
    { 
      src: img2, 
      title: 'Second Slide',
      description: 'This is the second slide description.',
      button1: 'Check Eligibility',
      button2: 'Contact Now'
    },
    { 
      src: img3, 
      title: 'Third Slide',
      description: 'This is the third slide description.',
      button1: 'Check Eligibility',
      button2: 'Contact Now'
    },
    { 
      src: img4, 
      title: 'Fourth Slide',
      description: 'This is the fourth slide description.',
      button1: 'Check Eligibility',
      button2: 'Contact Now'
    }
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
      <div className="carousel-indicators">
        {images.map((_, index) => (
          <span
            key={index}
            className={`carousel-dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
      </div>
      <div className="carousel-inner">
        {images.map((image, index) => (
          <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
            <img className="d-block w-100" src={image.src} alt={`${image.title} slide`} />
            <div className="carousel-caption d-none d-md-block">
              <h5 className="cruslImgTitl">{image.title}</h5>
              <p className="cruslImgDis">{image.description}</p>
              <button className="btn btn-primary">{image.button1}</button>
              <button className="btn btn-secondary">{image.button2}</button>
            </div>
          </div>
        ))}
      </div>
      <button className="carousel-control-prev" onClick={handlePrev}>
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </button>
      <button className="carousel-control-next" onClick={handleNext}>
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </button>
    </div>
  );
};

export default Carousel;


