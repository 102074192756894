import React from 'react';
import './Footer.css'; // Import the CSS file for styling
import fb from './image/fb.svg';
import x from './image/x.svg'
import insta from './image/insta.svg'
import lin from './image/lind.svg'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section about">
          <h3>About Us</h3>
          <hr className='hrStyle'/>
          <p>We provide comprehensive education loan solutions to help students achieve their academic goals. Our mission is to make education financing accessible and affordable.</p>
        </div>
        <div className="footer-section links">
          <h3>Quick Links</h3>
          <hr className='hrStyle'/>
          <ul>
            <li><a href="./">Home</a></li>
            <li><a href="./services">Services</a></li>
            <li><a href="./study-abroad">Study Abroad</a></li>
            <li><a href="./about-us">About Us</a></li>
            <li><a href="./contact-us">Contact Us</a></li>
          </ul>
        </div>
        <div className="footer-section social">
          <h3>Follow Us</h3>
          <hr className='hrStyle'/>
          <div className="social-icons">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <img src={fb} alt="Facebook" />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <img src={x} alt="Twitter" />
            </a>
            <a href="https://www.instagram.com/students_choice_consultancy/" target="_blank" rel="noopener noreferrer">
              <img src={insta} alt="Instagram" />
            </a>
            <a href="https://www.linkedin.com/company/student-s-choice/" target="_blank" rel="noopener noreferrer">
              <img src={lin} alt="LinkedIn" />
            </a>
          </div>
        </div>
        <div className="footer-section contact">
          <h3>Contact Us</h3>
          <hr className='hrStyle'/>
          <p><strong>Address:</strong>  144, Second Floor, WZ - 106, Rajouri Garden Extension, New Delhi, Delhi 110027</p>
          <p><strong>Email:</strong> <a href="mailto:info@studentschoice.in">info@studentschoice.in </a></p>
          <p><strong>Phone:</strong> <a href="tel:+91 98018 21680">+91 98018 21680</a> | <a href="tel:+91 70612 80928">+91 70612 80928</a></p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Students Choice. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
