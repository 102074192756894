import React from "react";

const WhatsAppButton = () => {
  const phoneNumber = "+911234567890"; // Replace with your phone number
const message = "Hello there! I would like to inquire about your services. Could you please provide more details? Thank you!";

  return (
    <a
      href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`}
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-button"
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png" // You can replace this with your preferred icon
        alt="WhatsApp"
        className="w-12 h-12"
      />
    </a>
  );
};

export default WhatsAppButton;