// Services.js
import React from 'react';
import './Services.css';
import ServicesMain from './ServicesMain';
import WhatsAppButton from '../WhatsAppButton/WhatsAppButton';

const Services = () => {
  return (
    <>
    <ServicesMain />
    <div className="services align-text">
      <h1>Our Services <span className='update'>Update comming soon</span></h1>
      <p>Welcome to our services page. Here we offer a variety of services to meet your needs.</p>
    </div>
    <WhatsAppButton />
    </>
  );
};

export default Services;
