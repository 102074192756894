// AboutUs.js
import React from 'react';
import './AboutUs.css';
import Part1 from './part1';
import Part2 from './Part2';
import WhatsAppButton from '../WhatsAppButton/WhatsAppButton';

const AboutUs = () => {
  return (
    <>
    <Part1/>
    <Part2/>
    <WhatsAppButton />
    </>
  );
};

export default AboutUs;
