import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ContactUs.css';
import contactImage from '../../image/contact1.png'; // Adjust path if needed
import WhatsAppButton from '../WhatsAppButton/WhatsAppButton';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    studentName: '',
    contactNumber: '',
    email: '',
    planningCountry: '',
    universityName: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement your form submission logic here (e.g., sending data to GoDaddy mail)
    console.log(formData); // For testing, log the form data
    // Clear form fields after submission if needed
    setFormData({
      studentName: '',
      contactNumber: '',
      email: '',
      planningCountry: '',
      universityName: ''
    });
  };

  return (
    <div className="container cont-us mt-5">
      <div className="row align-items-center">
        <div className="col-md-12">
          <h1 className='text-center'><span className="text-dark">Get In</span>
            <span className="touch-color"> Touch</span></h1>


          <p className='text-center'>
            Best Study Abroad Loans for 2024 - 2025:
            <ul className='bank-name'>
              <li>HDFC Credila</li>
              <li>Yes Bank</li>
              <li>Avanse Financial</li>
              <li>Incred</li>
              <li>Auxilo</li>
              <li>IDFC Bank</li>
              <li>ICICI Bank</li>
              <li>Union Bank</li>
            </ul>
            An abroad education loan is a financial support that helps students fund their studies abroad by paying for tuition fees, living expenses, and other related costs.
          </p>

        </div>

        <div className="col-md-6">
          <img src={contactImage} alt="Contact Us" className="img-fluid" />
        </div>
        <div className="col-md-6">


          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formStudentName">
              <Form.Label>STUDENT NAME *</Form.Label>
              <Form.Control type="text" placeholder="Enter your name" name="studentName" value={formData.studentName} onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="formContactNumber">
              <Form.Label>STUDENT CONTACT NUMBER *</Form.Label>
              <Form.Control type="text" placeholder="Enter your contact number" name="contactNumber" value={formData.contactNumber} onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>STUDENT - EMAIL ID *</Form.Label>
              <Form.Control type="email" placeholder="Enter your email" name="email" value={formData.email} onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="formPlanningCountry">
              <Form.Label>PLANNING COUNTRY *</Form.Label>
              <Form.Control type="text" placeholder="Enter the country you are planning to study in" name="planningCountry" value={formData.planningCountry} onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="formUniversityName">
              <Form.Label>UNIVERSITY NAME *</Form.Label>
              <Form.Control type="text" placeholder="Enter the university name" name="universityName" value={formData.universityName} onChange={handleChange} required />
            </Form.Group>

            <Button className="middle" variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </div>
      </div>
      <WhatsAppButton />
    </div>
  );
};

export default ContactUs;
