// StudyAbroad.js
import React from 'react';
import './StudyAbroad.css';
import Carousel from './Carousel';
import Marketing from './Marketing';
import WhatsAppButton from '../WhatsAppButton/WhatsAppButton';

const StudyAbroad = () => {
  return (
    <>
    <Carousel/>
    <Marketing/>
    <WhatsAppButton />
    </>
  );
};

export default StudyAbroad;
