import React from 'react';
import './Eligibility.css'; // Import CSS for styling

const Eligibility = ({ onClose }) => { // Destructure the onClose prop
 

    return (
        <div className="emi-calculator">
            <div className="emi-content">
                <h2>Eligibility Criteria</h2>
                <p>Eligibility criteria for applying for a loan:</p>
                <ul>
                    <li>Age between 21 and 58 years</li>
                    <li>Minimum monthly income of ₹20,000</li>
                    <li>Employed for at least 2 years</li>
                    <li>Good credit score</li>
                </ul>
                <p>Documents required:</p>
                <ul>
                    <li>Identity proof</li>
                    <li>Address proof</li>
                    <li>Income proof</li>
                    <li>Bank statements</li>
                </ul>
                <p>Interest rates and other charges:</p>
                <ul>
                    <li>Interest rate: 10% per annum</li>
                    <li>Processing fee: 1% of the loan amount</li>
                    <li>Foreclosure charges: 2% of the outstanding amount</li>
                </ul>
                <p>Loan amount and tenure:</p>
                <ul>
                    <li>Minimum loan amount: ₹1,00,000</li>
                    <li>Maximum loan amount: ₹10,00,000</li>
                    <li>Loan tenure: 1 to 5 years</li>
                </ul>
                
                <br />
                
                
                
                <button onClick={onClose}>Close</button> {/* Use the onClose prop to close the component */}
            </div>
        </div>
    );
};

export default Eligibility;
